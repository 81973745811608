import Navbar from "./components/Navbar";
import Card from "./components/Card";
import Footer from "./components/Footer";
import Snowfall from "./components/Snowfall";

const App = () => {
  return (
    <>
      <Navbar />

      <main className="content">
        <Card />
      </main>

      <Footer />

      {/* <Snowfall /> */}
    </>
  );
};

export default App;
