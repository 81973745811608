import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className="footer-wrapper">
      <div className="footer">
        <div className="footer-left">
          Copyright &copy; 2021 NetworkChuck. All rights reserved.
        </div>

        <div className="footer-right">
          <a
            className="footer-link"
            href="https://www.facebook.com/NetworkChuck/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className="footer-icon" icon={faFacebook} />
          </a>

          <a
            className="footer-link"
            href="https://twitter.com/networkchuck"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className="footer-icon" icon={faTwitter} />
          </a>

          <a
            className="footer-link"
            href="https://www.youtube.com/networkchuck"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className="footer-icon" icon={faYoutube} />
          </a>

          <a
            className="footer-link"
            href="https://www.instagram.com/networkchuck/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className="footer-icon" icon={faInstagram} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
