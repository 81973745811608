const Navbar = () => {
  return (
    <nav className="navbar">
      <a href="/">
        <img className="navbar-brand" src="/logo.png" alt="Network Chuck" />
      </a>
    </nav>
  );
};

export default Navbar;
