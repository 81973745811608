import { useState, useMemo, useEffect } from "react";
import {
  ConnectionProvider,
  useConnection,
  useWallet,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  getTorusWallet,
} from "@solana/wallet-adapter-wallets";
import {
  WalletModalProvider,
  WalletDisconnectButton,
  WalletMultiButton,
  WalletConnectButton,
  WalletModalButton,
} from "@solana/wallet-adapter-react-ui";
import {
  clusterApiUrl,
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import { struct, nu64, u8 } from "@solana/buffer-layout";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

// Constants
const RPC_GATEWAY =
  "https://rough-polished-resonance.solana-mainnet.quiknode.pro/de8ea0e7850e5b1b1daed32180d902c665ab8c10/";

const PROGRAM_ID = new PublicKey(
  "9DFgFhTDKJFwbzhfYEXMPNXA1aZN9hHZryZBg4y1poP1"
);
const TREASURY = new PublicKey("7K4cE8o4cYHpFNsw8KdrjoB6hMzvwiiNXST2qhN8DMwt");
const MINT = new PublicKey("A4zyBooAFkpfy7osonRJMQ8a6zArGxN5fNXjXo1ZTZK2");

const TOKEN_PROGRAM_ID = new PublicKey(
  "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
);
const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new PublicKey(
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
);
const RENT = new PublicKey("SysvarRent111111111111111111111111111111111");

const Mint = ({ amount }) => {
  const { connection } = useConnection();
  const { connect, connected, wallet, publicKey, sendTransaction, disconnect } =
    useWallet();

  const [isMinting, setIsMinting] = useState(false);

  const handleMint = (e) => {
    e.preventDefault();

    const promise = new Promise(async (resolve, reject) => {
      setIsMinting(true);

      try {
        const vaultEnc = new TextEncoder().encode("vault");
        const vaultPda = (
          await PublicKey.findProgramAddress([vaultEnc], PROGRAM_ID)
        )[0];

        const vaultMintHolder = (
          await PublicKey.findProgramAddress(
            [vaultPda.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), MINT.toBuffer()],
            SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
          )
        )[0];

        const walletMintHolder = (
          await PublicKey.findProgramAddress(
            [
              publicKey.toBuffer(),
              TOKEN_PROGRAM_ID.toBuffer(),
              MINT.toBuffer(),
            ],
            SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
          )
        )[0];

        const priceEnc = new TextEncoder().encode("price");
        const pricePda = (
          await PublicKey.findProgramAddress([priceEnc], PROGRAM_ID)
        )[0];

        const allocateTransaction = new Transaction({
          feePayer: publicKey,
        });

        const keys = [
          { pubkey: publicKey, isSigner: true, isWritable: true },
          {
            pubkey: SystemProgram.programId,
            isSigner: false,
            isWritable: true,
          },
          { pubkey: vaultPda, isSigner: false, isWritable: true },

          { pubkey: vaultMintHolder, isSigner: false, isWritable: true },
          { pubkey: MINT, isSigner: false, isWritable: false },
          { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
          { pubkey: RENT, isSigner: false, isWritable: false },
          {
            pubkey: SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
            isSigner: false,
            isWritable: false,
          },

          { pubkey: TREASURY, isSigner: false, isWritable: true },
          { pubkey: walletMintHolder, isSigner: false, isWritable: true },
          { pubkey: pricePda, isSigner: false, isWritable: true },
        ];

        const commandDataLayout = struct([u8("instruction"), nu64("amount")]);

        let data = Buffer.alloc(1024);

        {
          const encodeLength = commandDataLayout.encode(
            {
              instruction: 1,
              amount,
            },
            data
          );

          data = data.slice(0, encodeLength);
        }

        allocateTransaction.add(
          new TransactionInstruction({
            keys,
            programId: PROGRAM_ID,
            data,
          })
        );

        const signature = await sendTransaction(
          allocateTransaction,
          connection
        );

        toast.info("Transaction has been sent");

        await connection.confirmTransaction(signature, "confirmed");

        resolve();
      } catch (err) {
        console.error(err);
        reject();
      }

      setIsMinting(false);
    });

    toast.promise(promise, {
      pending: "Making transaction...",
      success: "Transaction has been confirmed",
      error: "Transaction failed",
    });
  };

  const handleDisconnect = (e) => {
    e.preventDefault();

    disconnect();
  };

  // useEffect(() => {
  //   toast.info("Making transaction...");
  //   toast.info("Transaction has been sent");
  //   toast.success("Transaction has been confirmed");
  //   toast.error("Transaction failed");
  // }, []);

  return (
    <>
      {connected ? (
        <>
          <button
            className="form-btn"
            onClick={handleMint}
            disabled={isMinting}
          >
            {isMinting ? "Minting..." : "Mint"}
          </button>

          <button
            className="form-btn form-btn--secondary"
            onClick={handleDisconnect}
          >
            Disconnect Wallet
          </button>
        </>
      ) : (
        <WalletModalButton className="form-btn">
          Connect Wallet
        </WalletModalButton>
      )}

      <ToastContainer
        theme="dark"
        position="bottom-left"
        hideProgressBar
        pauseOnFocusLoss={false}
        pauseOnHover={false}
      />
    </>
  );
};

const Wallet = ({ amount }) => {
  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = RPC_GATEWAY;

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getTorusWallet({
        options: { clientId: "Get a client ID @ https://developer.tor.us" },
      }),
      getLedgerWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <Mint amount={amount} />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

const Card = () => {
  const [formData, setFormData] = useState({
    from: 0.001,
    to: 1,
  });

  const handleChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    let from;
    let to;

    if (value < 0) {
      from = 0;
      to = 0;
    } else if (name === "from") {
      from = value;
      to = parseInt(value / 0.001);
    } else {
      from = Number((value * 0.001).toFixed(9));
      to = value;
    }

    setFormData({ from, to });
  };

  const handleBlur = (e) => {
    if (formData.to < 1) {
      // Set 'from' and 'to' to minimum
      setFormData({ from: 0.001, to: 1 });
    } else if (formData.to !== parseInt(formData.to)) {
      // Remove decimal from 'to' and re-calculate 'from'
      setFormData({
        from: Number((parseInt(formData.to) * 0.001).toFixed(9)),
        to: parseInt(formData.to),
      });
    } else if (formData.from * 1000 !== formData.to) {
      // Re-calculate 'from'
      setFormData({ ...formData, from: formData.to / 1000 });
    }
  };

  return (
    <div className="card">
      <h3 className="card-title">NetworkChuck Coin $NTCK</h3>
      <p className="card-description">
        Make sure you have enough SOL and then choose the amount of $NTCK.
      </p>

      <form className="form">
        <p className="form-helper">You Pay</p>

        <div className="form-group">
          <label className="form-label">SOL</label>
          <input
            className="form-input"
            name="from"
            type="number"
            value={formData.from}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>

        <p className="form-helper">You Receive</p>

        <div className="form-group">
          <label className="form-label">NTCK</label>
          <input
            className="form-input"
            name="to"
            type="number"
            value={formData.to}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>

        <div className="form-footer">
          <Wallet amount={formData.to} />
        </div>
      </form>
    </div>
  );
};

export default Card;
